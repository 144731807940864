import React, { Component } from 'react';
import axios from "axios";

import QrReader from 'modern-react-qr-reader'

import { IoIosSettings } from 'react-icons/io';
import { IoMdArrowRoundForward } from 'react-icons/io';



class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delay: 300,
            resolution: 1000,
            result: '',
            qrRead: false,
            qrCodeImage: '',
            qrReader: 1,
            step: 1,
            lead: '',
            note: '',
            message: 'Scan QR Code',
            invalidScanText: '',
            displayErrorBtn: false,
            displaySignInBtn: false,
        };

        this.handleError        = this.handleError.bind(this);
        this.handleScan         = this.handleScan.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this)

        this.textareaRef        = React.createRef();

    }

    componentDidMount() {
        document.getElementById("app-window").focus();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.step === 2) {
            this.textareaRef.current.focus();
        }
    }

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue});
    }


    handleScan = lead => {
        if(lead) {

            if (this.validateLead(lead)) {

                this.setState({invalidScanText: ''});

                this.setState({step: 2});
                this.setState({result: lead});
                this.setState({lead: lead});

                //this.postLead(); // Test loop
            } else {

                if(this.state.invalidScanText === '') {

                    this.setState({ invalidScanText: 'Invalid Lead' });
                    this.setState({ message: ''});

                    this.wait = setTimeout(function () {

                        clearInterval(this.wait);
                        this.setState({invalidScanText: ''});
                        this.setState({message: 'Scan QR Code'});

                    }.bind(this), 3000);

                }

            }

        }

    }

    validateLead(lead) {
        return !isNaN(parseFloat(lead)) && isFinite(lead) && lead > this.props.eventFirstLeadId
    }


    handleError(err){
        console.error(err)
    }

    postLead() {

        this.setState({ displayErrorBtn: false });

        var data            = {};
        data.lead           = this.state.lead;
        data.note           = this.state.note;
        data.token          = this.props.token;
        data.event_id       = this.props.eventId;

        this.setState({message: 'Sending Lead'});
        this.setState({step: 4});

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=postLead',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            if(responseObject.data.valid_token === true) {

                this.props.connectionError(false);
                this.setState({message: 'Lead has been registered'});

                this.wait = setTimeout(function () {

                    clearInterval(this.wait);
                    this.setState({message: ''});

                    /*
                     // Test code
                     console.log(responseObject);
                     this.postLead(); // Test loop
                     */


                    this.setState({note: ''});
                    this.setState({step: 1});



                }.bind(this), 2000); // Værdi skal være 2000

            } else {

                this.setState({message: responseObject.data.result});
                this.setState({ displaySignInBtn: true });

            }

        }).catch((error) => {

            console.log(error);

            this.props.connectionError(true);
            this.setState({message: 'ERROR: Lead has NOT been registered. This may be due to a lack of internet connection!'});
            this.setState({ displayErrorBtn: true });

        });

    }

    dismissLead(){

        this.setState({message: 'Lead has not been added'});
        this.setState({step: 0});

        this.wait = setTimeout(function () {

            clearInterval(this.wait);
            this.setState({note: ''});
            this.setState({step: 1});
            this.setState({message: ''});

        }.bind(this), 2000);

    }

    admin() {
        this.props.changeScreen('admin')
    }

    render() {

        const {eventActive, companyId, companyName, initials, colors, logoTop, logoBottom } = this.props;


        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const userStyle    = {
            color: colors.color_1 + 'BB'
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }

        const invalidScanStyle    = {
            color: colors.color_3,
            fontWeight: 'bold',
        }



        return (

            <div className="container-fluid d-flex align-items-center vh-100">
                <div className="row d-flex justify-content-center vh-100 vw-100">
                    <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                        {logoTop !== '' &&
                        <div className="section-top-wrapper">
                            <img id="logo" src={logoTop} alt="Logo"/>
                        </div>
                        }

                        <div className="content pt-2">
                            {this.state.message !== "" &&
                                <div id="message" className="mt-3 mb-3 text-center" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}>

                                </div>
                            }

                            {this.state.invalidScanText !== "" &&
                                <div id="message" className="mt-3 mb-3 text-center" style={invalidScanStyle} dangerouslySetInnerHTML={{__html: this.state.invalidScanText}}>

                                </div>
                            }

                            {this.state.displayErrorBtn &&
                                <div className="text-center">
                                    <button className="btn-medium m-2" type="button" style={btnStyle} onClick={() => this.postLead()}>
                                        SEND AGAIN <IoMdArrowRoundForward/>
                                    </button>
                                </div>
                            }

                            {this.state.displaySignInBtn &&
                            <div className="text-center">
                                <button className="btn-medium m-2" type="button" style={btnStyle} onClick={() => this.props.changeScreen('setup', '')}>
                                    SIGN IN <IoMdArrowRoundForward/>
                                </button>
                            </div>
                            }

                           {eventActive === 1 && this.state.qrReader === 1 && this.state.step === 1 &&
                               <div id="qr-wrapper" className="col-12 text-center">
                                   <div id="qr-container">
                                       <div id="qr-reader">

                                           <QrReader
                                               delay            = {this.state.delay}
                                               resolution       = {this.state.resolution}
                                               onError          = {this.handleError}
                                               onScan           = {this.handleScan}
                                               style            = {{ width: '100%' }}
                                               showViewFinder   = {false}
                                               />

                                           <p style={frontStyle}>{this.state.lead}</p>
                                       </div>
                                   </div>
                               </div>
                           }

                            {eventActive === 0 && this.state.qrReader === 1 && this.state.step === 1 &&
                                <div className="text-center">
                                    <div id="inactive">Lead Scan has been disabled</div>
                                </div>
                            }

                            {this.state.step === 2 &&
                                <div>
                                    <div className="mb-2" style={frontStyle}>Lead: { this.state.lead }</div>
                                    <div>
                                        <textarea
                                            className           = {"form-control pl-3"}
                                            rows                = "5"
                                            id                  = "note"
                                            name                = "note"
                                            placeholder         = "note"
                                            defaultValue        = ""
                                            onChange            = {this.handleInputChange}
                                            ref                 = {this.textareaRef}
                                            />
                                    </div>

                                    <div className="text-center mt-3">
                                        <button className="btn-medium m-2" type="button" style={btnStyle} onClick={() => this.dismissLead()}>
                                            DISMISS <IoMdArrowRoundForward/>
                                        </button>
                                        <button className="btn-medium m-2" type="button" style={btnStyle} onClick={() => this.postLead()}>
                                            SAVE <IoMdArrowRoundForward/>
                                        </button>
                                    </div>
                                </div>
                            }

                       </div>

                       <div className="section-bottom-wrapper mt-2">

                           {this.state.step === 1 &&
                               <div className="form-group text-center" style={userStyle}>

                                   <div id="user">
                                       <div>SIGNED IN AS</div>
                                       <div>Company: {companyName}</div>

                                       {initials !== '' &&
                                       <div>Initials: {initials}</div>
                                       }
                                   </div>

                                   {parseFloat(companyId) === 1 &&
                                       <button className="btn-medium m-2" type="button" style={btnStyle}
                                               onClick={() => this.admin()}>
                                           Admin <IoIosSettings/>
                                       </button>
                                   }

                                   {parseFloat(companyId) !== 1 &&
                                       <button className="btn-medium m-2" type="button" style={btnStyle}
                                               onClick={() => this.admin()}>
                                           Menu <IoIosSettings/>
                                       </button>
                                   }


                               </div>
                           }

                           {logoBottom !== '' &&
                           <div className="section-bottom-wrapper">
                               <div className="text-center mt-5">
                                   <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                               </div>
                           </div>
                           }
                       </div>

                   </div>
               </div>
           </div>

        );
    }
}

export default Home;