import React, { Component } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';

import { IoMdLogIn } from 'react-icons/io';
import { IoMdQrScanner } from 'react-icons/io';
import { IoMdSync } from 'react-icons/io';
import { IoMdContacts } from 'react-icons/io';
import { IoMdCloudUpload } from "react-icons/io";
import { IoIosSettings } from 'react-icons/io';


class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: '',
            companyId: '',
            initials: '',
            message: '',
            signedIn: true,
            eventSync: 0,
            eventActive: 1,
            exportEmail: false,
            exportJobTitle: false,
            exportCorporation: false,
            exportPhone: false,
            exportZipCode: false,
            exportDecisionmaker: false,
            deleteLeadsDate: '',
        };

        this.handleInputChange      = this.handleInputChange.bind(this);
        this.handleCheckboxChange   = this.handleCheckboxChange.bind(this);
        this.handleDateChange       = this.handleDateChange.bind(this);
    }

    componentDidMount() {

        document.getElementById("app-window").focus();

        this.setState({ eventId: this.props.eventId});
        this.setState({ companyId: this.props.companyId});
        this.setState({ initials: this.props.initials});

        if(this.props.event && this.props.event.hasOwnProperty('event_sync')) {
            this.setState({eventSync: this.props.event.event_sync});
            this.setState({eventActive: this.props.event.event_active});
            this.setState({exportEmail: parseFloat(this.props.event.export_email) === 1 ? true : false});
            this.setState({exportJobTitle: parseFloat(this.props.event.export_job_title) === 1 ? true : false});
            this.setState({exportCorporation: parseFloat(this.props.event.export_corporation) === 1 ? true : false});
            this.setState({exportPhone: parseFloat(this.props.event.export_phone) === 1 ? true : false});
            this.setState({exportZipCode: parseFloat(this.props.event.export_zip_code) === 1 ? true : false});
            this.setState({exportDecisionmaker: parseFloat(this.props.event.export_decisionmaker) === 1 ? true : false});
            this.setState({deleteLeadsDate: this.props.event.delete_leads_date === null ? '' : this.props.event.delete_leads_date});

            setTimeout(() => {
                this.setState({eventSync: this.props.event.event_sync});
                this.setState({exportEmail: parseFloat(this.props.event.export_email) === 1 ? true : false});
                this.setState({exportJobTitle: parseFloat(this.props.event.export_job_title) === 1 ? true : false});
                this.setState({exportCorporation: parseFloat(this.props.event.export_corporation) === 1 ? true : false});
                this.setState({exportPhone: parseFloat(this.props.event.export_phone) === 1 ? true : false});
                this.setState({exportZipCode: parseFloat(this.props.event.export_zip_code) === 1 ? true : false});
                this.setState({exportDecisionmaker: parseFloat(this.props.event.export_decisionmaker) === 1 ? true : false});
                this.setState({deleteLeadsDate: this.props.event.delete_leads_date === null ? '' : this.props.event.delete_leads_date});
            }, 2000);
        }

        if(this.props.event === null) {
            this.setState({ message: 'Event not found in App'});
        }
    }

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;
        this.setState({ [itemName]: itemValue});
    }

    handleCheckboxChange(e) {
        const itemName = e.target.id;
        const isChecked = e.target.checked;
        this.setState({ [itemName]: isChecked });
    }

    handleDateChange(e){
        this.setState({ deleteLeadsDate: e.target.value} );
    };

    updateEvent() {

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;

        this.setState({ message: 'Importing event'});

        var data                    = {};

        data.eventId                = this.state.eventId;
        data.companyId              = this.props.companyId;
        data.eventSync              = this.state.eventSync;
        data.eventActive            = this.state.eventActive;
        data.exportEmail            = this.state.exportEmail;
        data.exportJobTitle         = this.state.exportJobTitle;
        data.exportCorporation      = this.state.exportCorporation;
        data.exportPhone            = this.state.exportPhone;
        data.exportZipCode          = this.state.exportZipCode;
        data.exportDecisionmaker    = this.state.exportDecisionmaker;
        data.deleteLeadsDate        = this.state.deleteLeadsDate;
        data.token                  = this.props.token;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=updateEvent',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            if(responseObject.data.valid_token === true) {

                console.log('in');

                this.setState({ message: responseObject.data.message});

                console.log(responseObject.data);

                this.props.connectionError(false);
                this.props.setAdmin(this.state.eventId, responseObject.data);

            } else {

                console.log('in1');
                console.log(responseObject);

                var prop        = {};
                prop.appMessage = responseObject.data.result;
                this.props.changeScreen('setup', prop);

            }

        }).catch((error) => {

            console.log('in2');

            this.setState({ message: error});
            this.props.connectionError(true);
            console.log(error)

        });

    }

    startScan(){
        this.props.changeScreen('home', '');
    }

    signOut(){

        this.setState({ message: 'Signing Out' });
        this.setState({ signedIn: false });

        var data          = {};
        data.token        = this.props.token;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=signOut',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            Cookies.remove('authToken');
            this.props.connectionError(false);
            this.props.changeScreen('setup', 'clearEvent');

        }).catch((error) => {

            this.props.connectionError(true);
            console.log(error)

        });

    }

    changePassword() {
        this.props.changeScreen('changepassword', '');
    }

    render() {

        const {companyId, eventId, colors, event, active, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            width: '200px',
            cursor: 'pointer',
        }

        const eventSettingsStyle = {
            color: colors.color_1,
            border: '1px solid ' + colors.color_1,
            borderRadius: '10px',
            display: 'inline-block',
            marginBottom: '10px',
            padding: '5px 5px 25px 5px',
            textAlign: 'left'
        }

        const excelExportOptionsFirst = {
            borderTop: '1px solid #ffffff',
            borderBottom: '1px solid #ffffff',
            padding: '3px 0',
            marginTop: '10px'
        }

        const excelExportOptions = {
            borderBottom: '1px solid #ffffff',
            padding: '3px 0'
        }



        var updateText = 'Update Event';

        if(event === null) {
            updateText = 'Create Event';
            //this.setState({ message: 'Event not found in App'});
        }


        return (

                <form onSubmit={this.handleSubmit} className="form-post-lead">
                     <div className="container-fluid d-flex align-items-center vh-100">
                        <div className="row d-flex justify-content-center vh-100 vw-100">
                            <div className="content-wrapper page-setup col-12 col col-sm-8 col-lg-6">
                                <div className="content">

                                    {logoTop !== '' &&
                                    <div className="section-top-wrapper">
                                        <img id="logo" src={logoTop} alt="Logo"/>
                                    </div>
                                    }

                                    <div className="mt-3 mb-3" style={frontStyle}><strong>Admin</strong></div>

                                    {this.state.message !== "" &&
                                    <div id="message" className="mb-3" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                                    }

                                    {event &&
                                        <div className="mb-2 text-center" style={frontStyle}>
                                            <div><strong>EVENT</strong></div>
                                            <div>{event.event_name}</div>
                                            <div>#{eventId}</div>
                                        </div>
                                    }

                                    {!event &&
                                        <div>
                                            <div className="mb-1" style={frontStyle}>Event Id</div>
                                            <div className="mb-2">
                                                <input
                                                    className={"form-control pl-3"}
                                                    type="text"
                                                    id="eventId"
                                                    name="eventId"
                                                    placeholder="Event Id"
                                                    defaultValue={eventId}
                                                    onChange        = {this.handleInputChange}
                                                    />
                                            </div>
                                        </div>
                                    }

                                    {this.state.signedIn &&
                                        <div className="text-center">

                                            {parseFloat(companyId) === 1 &&
                                            <div>
                                                <div style={eventSettingsStyle}>

                                                    <div className="text-center mt-2"><strong>Event settings</strong>
                                                    </div>

                                                    <div className="text-center mt-2">
                                                        <select
                                                            id="eventActive"
                                                            name="eventActive"
                                                            className="app-dropdown"
                                                            value={this.state.eventActive}
                                                            onChange={this.handleInputChange}
                                                        >
                                                            <option value="0">Event closed for Scan</option>
                                                            <option value="1">Event active for Scan</option>

                                                        </select>
                                                    </div>

                                                    <div className="text-center mt-2">

                                                        <div>Sync Event</div>

                                                        <select
                                                            id="eventSync"
                                                            name="eventSync"
                                                            className="app-dropdown mt-1"
                                                            value={this.state.eventSync}
                                                            onChange={this.handleInputChange}
                                                        >
                                                            <option value="0">Disabled</option>
                                                            <option value="2">Every hour</option>
                                                            <option value="3">05:00 / 17:00</option>
                                                            <option value="4">05:00</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-2">
                                                        <div className="text-center mt-3">Excel export fields</div>
                                                        <div className="excel-export-settings">
                                                            <div style={excelExportOptionsFirst}>
                                                                <input
                                                                    type="checkbox"
                                                                    id="exportEmail"
                                                                    checked={this.state.exportEmail}
                                                                    onChange={this.handleCheckboxChange}
                                                                /><span> Email</span>
                                                            </div>
                                                            <div style={excelExportOptions}>
                                                                <input
                                                                    type="checkbox"
                                                                    id="exportJobTitle"
                                                                    checked={this.state.exportJobTitle}
                                                                    onChange={this.handleCheckboxChange}
                                                                /><span> Job title</span>
                                                            </div>
                                                            <div style={excelExportOptions}>
                                                                <input
                                                                    type="checkbox"
                                                                    id="exportCorporation"
                                                                    checked={this.state.exportCorporation}
                                                                    onChange={this.handleCheckboxChange}
                                                                /><span> Corporation</span>
                                                            </div>
                                                            <div style={excelExportOptions}>
                                                                <input
                                                                    type="checkbox"
                                                                    id="exportPhone"
                                                                    checked={this.state.exportPhone}
                                                                    onChange={this.handleCheckboxChange}
                                                                /><span> Phone number</span>
                                                            </div>
                                                            <div style={excelExportOptions}>
                                                                <input
                                                                    type="checkbox"
                                                                    id="exportZipCode"
                                                                    checked={this.state.exportZipCode}
                                                                    onChange={this.handleCheckboxChange}
                                                                /><span> Zip code</span>
                                                            </div>
                                                            <div style={excelExportOptions}>
                                                                <input
                                                                    type="checkbox"
                                                                    id="exportDecisionmaker"
                                                                    checked={this.state.exportDecisionmaker}
                                                                    onChange={this.handleCheckboxChange}
                                                                /><span> Decisionmaker</span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="excel-export-settings text-center mt-2 mb-4">
                                                        <div>
                                                            <label>Automatic Deletion Date for All Event Leads (No Undo)</label>
                                                        </div>
                                                        <input
                                                            type="date"
                                                            className="app-dropdown mt-1"
                                                            value={this.state.deleteLeadsDate}
                                                            onChange={this.handleDateChange}
                                                        />
                                                    </div>

                                                    <div className="text-center mt-2">
                                                        <button className="btn-medium" type="button" style={btnStyle}
                                                                onClick={() => this.updateEvent()}>
                                                            {updateText} <IoMdSync/>
                                                        </button>
                                                    </div>

                                                </div>

                                            </div>
                                            }


                                            {parseFloat(companyId) === 1 &&
                                                <div className="text-right m-2">
                                                    <button className="btn-medium" type="button" style={btnStyle}
                                                            onClick={() => this.props.changeScreen('logsystem', '')}>
                                                        System Log <IoIosSettings/>
                                                    </button>
                                                </div>
                                            }

                                            {event &&
                                                <div className="text-right m-2">
                                                    <button className="btn-medium" type="button" style={btnStyle}
                                                            onClick={() => this.props.changeScreen('leads', '')}>
                                                        Leads <IoMdContacts/>
                                                    </button>
                                                </div>
                                            }

                                            {event &&
                                            <div className="text-right m-2">
                                                <button className="btn-medium" type="button" style={btnStyle}
                                                        onClick={() => this.startScan()}>
                                                    Scan <IoMdQrScanner/>
                                                </button>
                                            </div>
                                            }

                                            {active === 1 && event &&
                                            <div className="text-right m-2">
                                                <button className="btn-medium" type="button" style={btnStyle}
                                                        onClick={() => this.props.changeScreen('upload', '')}>
                                                    Upload QR-Codes <IoMdCloudUpload />
                                                </button>
                                            </div>
                                            }

                                            {/* Change Password bruge ikke men er gemt til evt. senere brug.
                                            <div className="text-right m-2">
                                                <button className="btn-medium" type="button" style={btnStyle}
                                                        onClick={() => this.changePassword()}>
                                                    Change Password <IoMdLogIn/>
                                                </button>
                                            </div>
                                            */}

                                            {parseFloat(companyId) === 1 &&
                                            <div className="text-right m-2">
                                                <button className="btn-medium" type="button" style={btnStyle}
                                                        onClick={() => this.props.changeScreen('users', '')}>
                                                    Users <IoMdSync/>
                                                </button>
                                            </div>}

                                            {/* VIS IKKE RESCAN KNAP! */}
                                            {/* {1 == 2 && parseFloat(companyId) === 1 && */}
                                            <div className="text-right m-2">
                                                <button className="btn-medium" type="button" style={btnStyle}
                                                        onClick={() => this.props.changeScreen('upload', '')}>
                                                    Upload <IoMdCloudUpload/>
                                                </button>
                                            </div>

                                            <div className="text-right m-2">
                                                <button className="btn-medium" type="button" style={btnStyle}
                                                        onClick={() => this.signOut()}>
                                                    Sign Out <IoMdLogIn/>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {logoBottom !== '' &&
                                <div className="section-bottom-wrapper mt-5">
                                    <div className="text-center">
                                        <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </form>


        );
    }
}

export default Admin;